import React from 'react';
import logo from './id10t_logo.jpg';
import './App.css';

function App() {
  return (
    <div className="root">
      <div className="background">
        <img src={logo} className="id10t-logo" alt="logo" />
      </div>
    </div>
  );
}

export default App;
